import React from 'react';

import { Route, createBrowserRouter, createRoutesFromElements, defer } from "react-router-dom";

import LoginPage    from "./pages/Login";
import HomePage     from "./pages/Home";
// import SignUpPage   from "./pages/SignUp";
import ProfilePage  from "./pages/Profile";
import SettingsPage from "./pages/Settings";
import MQTTPage from "./pages/MQTT";
import BarcodePage from "./pages/Barcode";
import FetchPage from "./pages/Fetch";
import InputPage from "./pages/Input";

import { ProtectedLayout } from "./components/ProtectedLayout";
import { HomeLayout } from "./components/HomeLayout";
import { AuthLayout } from "./components/AuthLayout";

const getUserData = () =>
  new Promise((resolve) =>
    setTimeout(() => {
      const user = window.localStorage.getItem("user");
      resolve(user);
    }, 1000)
  );


export const router = createBrowserRouter(
    createRoutesFromElements(
      <Route 
        element={<AuthLayout />}
        loader={() => defer({ userPromise: getUserData() })}
      >
        <Route element={<HomeLayout />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<LoginPage />} />
        </Route>

        <Route path="/dashboard" element={<ProtectedLayout />}>
          <Route path="profile" element={<ProfilePage />} />
          <Route path="settings" element={<SettingsPage />} />
          <Route path="mqtt" element={<MQTTPage />} />
          <Route path="barcode" element={<BarcodePage />} />
          <Route path="fetch" element={<FetchPage />} />
          <Route path="input" element={<InputPage />} />
        </Route>
      </Route>
    ),{ basename: "/" }
);

