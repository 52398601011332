import React, { createContext, useEffect, useState } from 'react'
import { Card, Button, Form, Input, Row, Col, Select } from 'antd'



const Subscriber = ({ mqttStatus, mqttPayload, mqttSubscribe, mqttUnSubscribe, mqttTopic, mqttQoS}) => {

    const [status, setStatus] = useState(false)
    const [payload, setPayload] = useState('')

    const values = {
        topic: mqttTopic,
        qos: parseInt(mqttQoS),  // Fehler, wenn als String verwendet wird!
    }
   
    useEffect( () => {
        let statusTmp = mqttStatus == 'connected';

        if(statusTmp && mqttSubscribe){
            mqttSubscribe(values);
            console.log('Subscribe() for topic: ' + mqttTopic + ' / qos = ' + mqttQoS);
        }

        setStatus(statusTmp);

    }, [mqttStatus]);

    useEffect( () => {
        if( (mqttTopic != '') && (mqttPayload.topic == mqttTopic)){

            console.log('new payload: ' + mqttTopic + ' = ' + mqttPayload.topic);

            setPayload(mqttPayload.message);
        }
    }, [mqttPayload]);

    return (
        <>
            <div>----------</div>
            <div>{mqttTopic} / {mqttQoS} - {mqttStatus}</div>
            <div>last payload: {payload}</div>
            <div>----------</div>
        </>
    );
}


export default Subscriber