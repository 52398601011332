import { BasicPage } from "../components/BasicPage";
import Edit from "@mui/icons-material/Edit";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container"; 


const InputPage = () => {
  return (
    <>
        <BasicPage title="Input Test Page" icon={<Edit />} />

        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
                }}
            >
                <Typography component="h1" variant="h5">
                Input Test
                </Typography>
                <Box component="form" noValidate sx={{ mt: 1 }}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                />
                <TextField
                    margin="normal"
                    fullWidth
                    id="test1"
                    label="Test 1"
                    name="test1"
                />
                <TextField
                    margin="normal"
                    fullWidth
                    id="test2"
                    label="Test 2"
                    name="test2"
                />
                <TextField
                    margin="normal"
                    fullWidth
                    id="test3"
                    label="Test 3"
                    name="test3"
                />
                <TextField
                    margin="normal"
                    fullWidth
                    id="test4"
                    label="Test 4"
                    name="test4"
                />
                <TextField
                    margin="normal"
                    fullWidth
                    id="test5"
                    label="Test 5"
                    name="test5"
                />
                <TextField
                    margin="normal"
                    fullWidth
                    id="test6"
                    label="Test 6"
                    name="test6"
                />
                <TextField
                    margin="normal"
                    fullWidth
                    id="test7"
                    label="Test 7"
                    name="test7"
                />
                <TextField
                    margin="normal"
                    fullWidth
                    id="test8"
                    label="Test 8"
                    name="test8"
                />
                <TextField
                    margin="normal"
                    fullWidth
                    id="test9"
                    label="Test 9"
                    name="test9"
                />
                <TextField
                    margin="normal"
                    fullWidth
                    id="test10"
                    label="Test 10"
                    name="test10"
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Test
                </Button>
                </Box>
            </Box>
        </Container>

    </>
  );
};

  export default InputPage;
