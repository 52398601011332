import React, { createContext, useEffect, useState, cloneElement, Children } from 'react'
//import { Card, Button, Form, Input, Row, Col, Select } from 'antd'
import { Button } from 'antd'

import Subscriber from '../pages/subscriber'

import mqtt from 'mqtt'

const MqttHook = (props) =>{

    const [client, setClient] = useState(null)  
    const [payload, setPayload] = useState({})
    const [connectStatus, setConnectStatus] = useState('disconnected')  
        
    let onError = props.onErrror;

    function sendErrorMsg(error){
      if(onError){
        onError(error);
      }
    }

    const mqttConnect = (host, mqttOption) => {
      try{
        console.log('connecting...')
        sendErrorMsg('connecting: ' + host);
        setConnectStatus('connecting')
        setClient(mqtt.connect(host, mqttOption))        
      } catch (error) {
        sendErrorMsg('connect error: ' + error);
        console.log('connect error:', error)
      }
    }

    const mqttDisconnect = () => {
      if (client) {
        try {
            client.end(false, () => {
            setConnectStatus('disconnected')
            console.log('disconnected successfully')
            })
        } catch (error) {
            sendErrorMsg('disconnect error: ' + error);

            console.log('disconnect error:', error)
        }
      }
  }

    const mqttPublish = (context) => {
      if (client) {
        const { topic, qos, payload } = context
        client.publish(topic, payload, { qos }, (error) => {
          if (error) {
            sendErrorMsg('Publish error: ' + error);
            console.log('Publish error: ', error)
          }
        })
      }
    }
  
    const mqttSub = (subscription) => {
      if (client) {
        const { topic, qos } = subscription

        client.subscribe(topic, { qos }, (error) => {
          if (error) {
            sendErrorMsg('Subscribe error: ' + error);
            console.log('Subscribe to topics error', error)
            return
          }
          console.log(`Subscribe to topics: ${topic}`)
        })
      }
    }
  
    const mqttUnSub = (subscription) => {
      if (client) {
        const { topic, qos } = subscription
        client.unsubscribe(topic, { qos }, (error) => {
          if (error) {
            sendErrorMsg('Unsubscribe error: ' + error);
            console.log('Unsubscribe error', error)
            return
          }
          console.log(`unsubscribed topic: ${topic}`)
        })
      }
    }
  
    //==================================================================================

    const handleConnect = () => {
        //const url = `ws://no172.FS.local:8080`

        let url = props.protocol + '://' + props.host + ':' + props.port + '/mqtt';

        const options = {
          clientId: 'FPLIMS_' + Math.random().toString(16).substring(2, 10),
          username: '',
          password: '',
          clean: true,
          reconnectPeriod: 1000, // ms
          connectTimeout: 30 * 1000, // ms
          rejectUnauthorized: false, 
        }

        mqttConnect(url,options)
    }

    const handleDisconnect = () => {
        mqttDisconnect()
    }

    useEffect(() => {
        if (client) {
          // https://github.com/mqttjs/MQTT.js#event-connect
          client.on('connect', () => {
            setConnectStatus('connected')
            console.log('connection successful')
          })
    
          // https://github.com/mqttjs/MQTT.js#event-error
          client.on('error', (err) => {
            sendErrorMsg('Connection error: ', err)
            console.error('Connection error: ', err)
            client.end()
          })
    
          // https://github.com/mqttjs/MQTT.js#event-reconnect
          client.on('reconnect', () => {
            mqttDisconnect();

            setConnectStatus('reconnecting')
            console.log('reconnecting...')
          })
    
          // https://github.com/mqttjs/MQTT.js#event-message
          client.on('message', (topic, message) => {
            const payload = { topic, message: message.toString() }
            setPayload(payload)
            console.log(`received message: ${message} from topic: ${topic}`)
          })
        }
    }, [client])
    
    const childrenWithProps = Children.map(props.children, (child, index) => {
      return React.cloneElement(child, {
        mqttStatus: connectStatus,
        mqttPayload: payload,
        mqttSubscribe: mqttSub,       
        mqttUnSubscribe: mqttUnSub, 
      });
    });

    return (
        <>
            <div>MQTT-Test</div>
            <div>Status: {connectStatus}</div>
            {connectStatus == 'connected'
              ?
              <Button onClick={handleDisconnect}>Disconnect</Button>
              :
              <Button onClick={handleConnect}>Connect</Button>
            }

            <Subscriber 
              mqttStatus={connectStatus} 
              mqttPayload={payload}
              mqttSubscribe={mqttSub}
              mqttUnSubscribe={mqttUnSub}
              mqttTopic="fplims/test"
              mqttQoS="0"
            />
                         
            <Subscriber 
              mqttStatus={connectStatus} 
              mqttPayload={payload}
              mqttSubscribe={mqttSub}
              mqttUnSubscribe={mqttUnSub}
              mqttTopic="fplims/test_3"
              mqttQoS="0"
            />

            <div>
              {childrenWithProps}   
            </div>
        </>
    );
} 


export default MqttHook
