import React, { useState, useEffect } from "react";

import { BasicPage } from "../components/BasicPage";
import Download from "@mui/icons-material/Download";
import { Result } from "antd";

const FetchPage = () => {

    const [data, setData] = useState(null);
    
    useEffect(() => {
      fetch("https://react.finksoftware.de/test.txt", {
        method: "GET",
        // mode: "cors",
        headers: {
        //  "KEY": "VALUE",
        },
      })
        .then((response) => {
            response.text().then( (r) => {
                setData(r);
                console.log(r);
            })
        })
        .catch((error) => console.log(error));
    },[]);
  

  return (
    <>
        <BasicPage title="Fetch Test" icon={<Download />} />
        <div>
            <h2>DATA:</h2>
            {data && <p>{data}</p>}
        </div>

    </>

  );



};

  export default FetchPage;
