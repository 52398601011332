import React, {useState} from 'react';

import { BasicPage } from "../components/BasicPage";
import Cloud from "@mui/icons-material/Cloud";

import MqttHook from '../components/hook/mqtt'
import Subscriber from '../components/pages/subscriber'



export default function MQTTPage() {

  const PROTOCOL = 'wss';
  
  const HOST = 'broker.emqx.io'; 
  const PORT = 8084;
  
  //const HOST = 'no172.fs.local';
  //const PORT = 8883;

  const [errorMsg, setErrorMsg] = useState('-');

  const handleErrorMsg = (msg) => {
    setErrorMsg(msg);
  }

  return (
    <>
      <BasicPage title="MQTT TEST PAGE" icon={<Cloud />} />
      <div>Server: {PROTOCOL}://{HOST}:{PORT}</div>
      <div>Topics: fplims/test fplims/test_1 fplims/test_2 fplims/test_3</div>
      <div>---</div>
      <div>{errorMsg}</div>
      <div>---</div>

      <MqttHook protocol={PROTOCOL} host={HOST} port={PORT} onErrror={handleErrorMsg}>

        <Subscriber
          mqttStatus="unknown" 
          mqttPayload={null} 
          mqttSubscribe={null} 
          mqttTopic="fplims/test_1" 
          mqttQoS="0"
        />

         <Subscriber
            mqttStatus="unknown" 
            mqttPayload={null} 
            mqttSubscribe={null} 
            mqttTopic="fplims/test_2" 
            mqttQoS="0"
          />
        
      </MqttHook>
    </>
    
     
  );
}

  
