import React, {useState} from 'react';
import {QrScanner} from '@yudiel/react-qr-scanner';

import { BasicPage } from "../components/BasicPage";
import QrCode2 from "@mui/icons-material/QrCode2";

export default function BarcodePage() {

    const [code, setCode] = useState('');

    const defaultConstraints = {
      facingMode: 'environment',
      width: { min: 640, ideal: 720, max: 1920 },
      height: { min: 640, ideal: 720, max: 1080 }
    };

    const handleScan = (barcode) =>{
      setCode(barcode);
      console.log(barcode);
    }
  
    const handleScanError = (error) =>{
      setCode('---');
      console.log(error?.message);
    }
    
    return (
        <>
            <BasicPage title="BARCODE SCANNER" icon={<QrCode2 />} />

            <div style={{ border: '1px solid red', width: 320 }}> 
                <QrScanner
                    onDecode={handleScan}
                    onError={handleScanError}

                    constraints={defaultConstraints}
                    scanDelay={50}
                    tracker={false}
    
                />
            </div>
        <div>Barcode: [{code}]</div>
        </>    
    );
}

  
